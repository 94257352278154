import { Grid } from "@mui/material";
import Typography from "../../../../components/Typography";
import { DefinitionBackgroundStyled, DefinitionTitleStyled } from "./styled";
import { useTheme } from "styled-components";
import ComparingChart from "./assets/ComparingChart";
import Spacer from "../../../../components/Spacer";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";

interface DefinitionProps {
  loading: boolean;
  onClick: () => void;
}

const Definition = ({ loading = false, onClick }: DefinitionProps) => {
  const theme = useTheme();
  return (
    <DefinitionBackgroundStyled>
      <Grid
        container
        justifyContent={"space-between"}
        columnSpacing={theme.spacings.m}
        rowSpacing={theme.spacings.l}
      >
        <Grid item xs={12} md>
          <DefinitionTitleStyled>{"O que é um"}</DefinitionTitleStyled>
          <DefinitionTitleStyled color={theme.colors.primary}>
            {"empréstimo consignado?"}
          </DefinitionTitleStyled>
          <Spacer y={"m"} />
          <Typography.Body2>
            {
              "É um facilitador financeiro com parcelas descontadas diretamente da folha de pagamento do colaborador."
            }
          </Typography.Body2>
          <Spacer y={"m"} />
          <Typography.Body2>
            {
              "Além disso, é uma modalidade de crédito com juros mais baixos e prazos mais longos para o pagamento, com parcelas que não podem ultrapassar 35% do salário líquido."
            }
          </Typography.Body2>
          <Spacer y={"m"} />
          <Button
            loading={loading}
            onClick={onClick}
            size="large"
            hidden={true}
            variant="primary"
          >
            {"Quero falar com especialista"}
          </Button>
        </Grid>
        <Grid item xs={12} md={"auto"}>
          <ComparingChart />
        </Grid>
      </Grid>
    </DefinitionBackgroundStyled>
  );
};

export default Definition;
