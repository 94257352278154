import {
  IconTypes,
  LinkButton,
  LinkButtonProps,
  NotificationCard,
} from "@flash-tecnologia/hros-web-ui-v2";
import { NotificationCardProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/NotificationCard/Root";
import Spacer from "../../../../components/Spacer";
import { LinkButtonContainer } from "./styled";

export interface NotificationBannerProps {
  redirectLink?: {
    onClick: () => void;
    text: string;
    variant: LinkButtonProps["variant"];
  };
  iconName: IconTypes;
  subtitle: string;
  title: string;
  type: NotificationCardProps["type"];
}

interface NotificationBannersProps {
  fileProcessingNotificationCard?: NotificationBannerProps;
  outdatedDatabaseNotificationCard?: NotificationBannerProps;
  govNotificationBannerCard?: NotificationBannerProps;
}

const NotificationBanners = ({
  fileProcessingNotificationCard,
  outdatedDatabaseNotificationCard,
  govNotificationBannerCard,
}: NotificationBannersProps) => {
  return (
    <>
      {fileProcessingNotificationCard && (
        <>
          <NotificationCard.Root
            type={fileProcessingNotificationCard.type}
            variant={"outlined"}
            autoClose
            showCloseButton
          >
            <NotificationCard.Icon
              iconName={fileProcessingNotificationCard.iconName}
            />
            <NotificationCard.WrapperTexts>
              <NotificationCard.Title>
                {fileProcessingNotificationCard.title}
              </NotificationCard.Title>
              <NotificationCard.Subtitle>
                {fileProcessingNotificationCard.subtitle}
              </NotificationCard.Subtitle>
              {fileProcessingNotificationCard.redirectLink && (
                <LinkButtonContainer>
                  <LinkButton
                    variant={
                      fileProcessingNotificationCard.redirectLink.variant
                    }
                    size="small"
                    onClick={
                      fileProcessingNotificationCard.redirectLink.onClick
                    }
                  >
                    {fileProcessingNotificationCard.redirectLink.text}
                  </LinkButton>
                </LinkButtonContainer>
              )}
            </NotificationCard.WrapperTexts>
          </NotificationCard.Root>
          <Spacer y={"xs"} />
        </>
      )}
      {outdatedDatabaseNotificationCard && (
        <NotificationCard.Root
          type={outdatedDatabaseNotificationCard.type}
          variant={"outlined"}
          autoClose
          showCloseButton
        >
          <NotificationCard.Icon
            iconName={outdatedDatabaseNotificationCard.iconName}
          />
          <NotificationCard.WrapperTexts>
            <NotificationCard.Title>
              {outdatedDatabaseNotificationCard.title}
            </NotificationCard.Title>
            <NotificationCard.Subtitle>
              {outdatedDatabaseNotificationCard.subtitle}
            </NotificationCard.Subtitle>
          </NotificationCard.WrapperTexts>
        </NotificationCard.Root>
      )}
      {govNotificationBannerCard && (
        <>
          <NotificationCard.Root
            type={govNotificationBannerCard.type}
            variant={"outlined"}
            autoClose
            showCloseButton
          >
            <NotificationCard.Icon
              iconName={govNotificationBannerCard.iconName}
            />
            <NotificationCard.WrapperTexts>
              <NotificationCard.Title>
                {govNotificationBannerCard.title}
              </NotificationCard.Title>
              <NotificationCard.Subtitle>
                {govNotificationBannerCard.subtitle}
              </NotificationCard.Subtitle>
              {govNotificationBannerCard.redirectLink && (
                <LinkButtonContainer>
                  <LinkButton
                    variant={govNotificationBannerCard.redirectLink.variant}
                    size="small"
                    onClick={govNotificationBannerCard.redirectLink.onClick}
                  >
                    {govNotificationBannerCard.redirectLink.text}
                  </LinkButton>
                </LinkButtonContainer>
              )}
            </NotificationCard.WrapperTexts>
          </NotificationCard.Root>
          <Spacer y={"xs"} />
        </>
      )}
    </>
  );
};

export default NotificationBanners;
