import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import Typography from "../../../../components/Typography";
import { useTheme } from "styled-components";
import FlexBox from "../../../../components/atoms/FlexBox";
import {
  ContainerWrapper,
  DiscoverOurSolutionDescriptionStyled,
  DiscoverOurSolutionDescriptionWrapperStyled,
} from "./styled";
import Spacer from "../../../../components/Spacer";

interface DiscoverOurSolutionProps {
  loading?: boolean;
  onClick: () => void;
}

const DiscoverOurSolution = ({
  loading = false,
  onClick,
}: DiscoverOurSolutionProps) => {
  const theme = useTheme();
  return (
    <ContainerWrapper>
      <FlexBox flexDirection="column" alignItems="center">
        <Spacer y={"l"} />
        <Typography.Headline8 color={theme.colors.secondary[50]}>
          Conheça o Empréstimo Consignado da Flash!
        </Typography.Headline8>
        <Spacer y={"xs"} />
        <Typography.Headline5 center>
          Descubra nossa solução focada na saúde financeira dos colaboradores.
        </Typography.Headline5>
        <Spacer y={"xs4"} />
        <DiscoverOurSolutionDescriptionWrapperStyled>
          <DiscoverOurSolutionDescriptionStyled
            color={theme.colors.neutral[50]}
          >
            Mais de
          </DiscoverOurSolutionDescriptionStyled>
          <DiscoverOurSolutionDescriptionStyled
            color={theme.colors.primary}
            weight={700}
          >
            70% das pessoas
          </DiscoverOurSolutionDescriptionStyled>
          <DiscoverOurSolutionDescriptionStyled
            color={theme.colors.neutral[50]}
          >
            sentem a produtividade baixar quando estão preocupadas com as
            finanças. O empréstimo consignado é um benefício que pode ajudar a
            melhorar a saúde financeira dos seus colaboradores.
          </DiscoverOurSolutionDescriptionStyled>
        </DiscoverOurSolutionDescriptionWrapperStyled>
        <Spacer y={"m"} />
        <FlexBox.Item>
          <Button
            size="large"
            variant="primary"
            onClick={onClick}
            loading={loading}
            hidden={true}
          >
            Quero falar com especialista
          </Button>
        </FlexBox.Item>
      </FlexBox>
    </ContainerWrapper>
  );
};

export default DiscoverOurSolution;
