import {
  Accordion,
  Breadcrumbs,
  Button,
  Divider,
  DownloadButton,
  Dropzone,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
  ShapeIcon,
  Spinner,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Spacer from "../../components/Spacer";
import Typography from "../../components/Typography";
import { PayrollLoanPageTabs } from "../../types/payrollLoanPageTabs";
import { fieldInstructions } from "./fieldInstructions";
import * as SC from "./styled";
import { useUploadSheetViewModel } from "./viewModel/useUploadSheetViewModel";

export default function UploadSheetPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    clearUploadedFiles,
    handleDropzoneChange,
    isLoading,
    templateSheetLink,
    uploadImageHandler,
    sendFinishedFileProcessingMutation,
    isGetUploadSheetLinkLoading,
    handleOpenFileDetailsAccordion,
  } = useUploadSheetViewModel();

  return (
    <>
      <PageHeader style={{ flexDirection: "column" }}>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              key={0}
              onClick={() => {
                navigate("/payroll-loan", {
                  replace: true,
                  state: { initialTab: PayrollLoanPageTabs.Employees },
                });
              }}
              variant="neutral"
              size="small"
            >
              Base
            </LinkButton>,
            <LinkButton key={1} variant="neutral" size="small" disabled>
              Atualizar base
            </LinkButton>,
          ]}
          separator={<Icons name="IconChevronRight" fill="#ffffff" />}
        />
        <Spacer y="xs" />
      </PageHeader>
      <PageContainer>
        <Typography.Headline6>Atualizar base</Typography.Headline6>
        <Spacer y="xs" />
        <Grid container spacing={theme.spacings.xs}>
          <Grid item xs={12} md={3}>
            <Typography.Headline7 color={theme.colors.secondary[50]}>
              Envie os dados
            </Typography.Headline7>
            <Spacer y="xs3" />
            <Typography.Body3 color={theme.colors.neutral[50]}>
              Envie os dados das pessoas por planilha para o cadastro em lote.
              Depois de enviar, você vai poder conferir o status do envio e
              corrigir possíveis erros.
            </Typography.Body3>
          </Grid>
          <Grid item xs={12} md>
            <SC.BoxContainer>
              <Typography.Headline8 color={theme.colors.neutral[30]}>
                Baixe o arquivo modelo
              </Typography.Headline8>
              <Spacer y="xs5" />
              <Typography.Body4 color={theme.colors.neutral[50]} weight={400}>
                Use o modelo para preencher os dados dos colaboradores com
                acesso ao benefício. É possível atualizar dados de colaboradores
                de todas as empresas do grupo que oferecem o benefício.
              </Typography.Body4>
              <Spacer y="xs2" />
              {isGetUploadSheetLinkLoading ? (
                <SC.LoadingContainer>
                  <Spinner size={48} variant="primary" />
                  <Typography.Body4
                    color={theme.colors.neutral[50]}
                    weight={700}
                  >
                    Carregando planilha
                  </Typography.Body4>
                </SC.LoadingContainer>
              ) : (
                <DownloadButton
                  title={"Baixar modelo"}
                  fileName={"flash_consignado.xlsx"}
                  filePath={templateSheetLink}
                  icon={"IconDownload"}
                  docIconSize={32}
                />
              )}
              <Spacer y="m" />
              <Accordion
                defaultExpanded={false}
                variant="disabled"
                title={
                  <Typography.Headline9 color={theme.colors.neutral[30]}>
                    {fieldInstructions.header.title}
                  </Typography.Headline9>
                }
                description={
                  <Typography.Caption
                    color={theme.colors.neutral[50]}
                    weight={600}
                  >
                    {fieldInstructions.header.description}
                  </Typography.Caption>
                }
                leftIcon={
                  <ShapeIcon
                    name="IconListDetails"
                    variant="default"
                    size={48}
                    color={theme.colors.primary}
                  />
                }
                children={fieldInstructions.fields.map((instruction, index) => (
                  <div key={index}>
                    {index !== 0 && <Divider orientation="horizontal" />}
                    <SC.AccordionItemContainer>
                      <SC.AccordionItemHeader>
                        <Icons
                          name={instruction.icon}
                          fill="transparent"
                          size={16}
                        />
                        <Typography.Body4
                          color={theme.colors.neutral[30]}
                          children={instruction.title}
                        />
                        <Tag variant="primary" as="span" size="extra-small">
                          {instruction.tag}
                        </Tag>
                      </SC.AccordionItemHeader>
                      <SC.AccordionItemList>
                        {instruction.bullets.map((item, index) => (
                          <li key={index}>
                            <Typography.Caption
                              color={theme.colors.neutral[50]}
                              children={item}
                            />
                          </li>
                        ))}
                      </SC.AccordionItemList>
                    </SC.AccordionItemContainer>
                  </div>
                ))}
                onChange={(_, expanded) =>
                  expanded && handleOpenFileDetailsAccordion()
                }
              />
            </SC.BoxContainer>
            <Spacer y="m" />
            <SC.BoxContainer>
              <Typography.Headline8 color={theme.colors.neutral[30]}>
                Envie seu arquivo
              </Typography.Headline8>
              <Spacer y="xs5" />
              <Typography.Body4 color={theme.colors.neutral[50]}>
                Com o arquivo em mãos, agora é só anexar abaixo!
              </Typography.Body4>
              <Spacer y="xs" />
              <Grid container>
                <Grid item xs={12} lg={7}>
                  <Dropzone
                    title="Seu arquivo"
                    onChange={handleDropzoneChange}
                    onRemove={clearUploadedFiles}
                    accept={["xlsx"]}
                  />
                </Grid>
              </Grid>
            </SC.BoxContainer>
          </Grid>
        </Grid>
        <Spacer y="xs" />
      </PageContainer>
      <SC.FooterContainer>
        <LinkButton
          variant="neutral"
          size="small"
          style={{ marginTop: "10px" }}
          onClick={() => navigate("/payroll-loan", { replace: true })}
        >
          Cancelar
        </LinkButton>
        <Button
          size="small"
          variant="primary"
          loading={Boolean(isLoading)}
          variantType="default"
          disabled={true}
          onClick={async () => {
            const response = await uploadImageHandler();
            if (response.ok) {
              sendFinishedFileProcessingMutation();
            }
          }}
        >
          Continuar
          <Icons
            name="IconArrowNarrowRight"
            color={theme.colors.neutral[100]}
            fill="transparent"
          />
        </Button>
      </SC.FooterContainer>
    </>
  );
}
